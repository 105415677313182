import React, { useEffect, useRef } from "react";
import { useGlobalLocation } from "../../shared/LocationContext.js";

const AdManagerSlot = ({ slotNumber }) => {

  const { location } = useGlobalLocation();
  const previouslocation = useRef("");

  useEffect(() => {
    if (typeof window !== "undefined" && window.googletag) {
      window.googletag.cmd.push(function () {
        window.googletag.display(slotNumber);
      });
    }
  }, []);
  // Refresh ad slot when the article changes
  useEffect(() => {
    if (previouslocation.current && previouslocation.current !== location.pathname) {
      if (typeof window !== "undefined" && window.googletag) {
        window.googletag.cmd.push(function () {
          window.googletag.pubads().refresh();
        });
      }
    }
    previouslocation.current = location.pathname;
  }, [location.pathname]);

  return <div id={slotNumber}></div>;
};

export default AdManagerSlot;
