// import { useGlobalLocation } from "./LocationContext.js";
export const urlTitle2 = (title) => {
  const cyrilic = ["č", "ć", "ž", "š", "đ", ",", ":", "-", "?", "!", "."];
  const replArray = ["c", "c", "z", "s", "dj", "", "", "", "", "", ""];
  let regex = /--/gi;
  let url_title = title
    .toLowerCase()
    .split("")
    .map((x) => {
      return cyrilic.indexOf(x) === -1 ? x : replArray[cyrilic.indexOf(x)];
    })
    .join("")
    .split(" ")
    .join("-")
    .replace(regex, "-");
  return url_title;
};
export const id2 = (pathPart) => {
  const labels = {
    subvencije: "Subvencije",
    "produktna-berza": "Produktna berza",
  };
  return labels[pathPart[2]] || null;
};
export const setSource = (item) => {
  if (Array.isArray(item)) {
    return `Autor: ${item[1]}`;
  } else {
    return `Izvor: ${item}`;
  }
};
