import React, { useState, Suspense, lazy, useLayoutEffect } from "react";
import "./Bars.scss";
import Backdrop from "./Backdrop/Backdrop.js";
// import Logo from "../../img/agroweb-logo.png";
import { FunctionProvider } from "../Helpers/Help.js";
import { useGlobalLocation } from "../../shared/LocationContext.js";
import { id2 } from "../../shared/utillity.js";
const TopBar = lazy(() => import("./TopBar/TopBar.js"));
const NavBar = lazy(() => import("./NavBar/NavBar.js"));
const ToggleButton = lazy(() => import("./SideDrawer/ToggleButton.js"));
const SideDrawer = lazy(() => import("./SideDrawer/SideDrawer.js"));
// const PostImage = lazy(() => import("../../containers/SinglePost/img/PostImage"));

export default function Bars() {
  const [sideDrawerOpen, setSiteDrawerOpen] = useState(false);
  const [barName, setBarName] = useState("bars");
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [admin, setAdmin] = useState(false);
  const { pathPart } = useGlobalLocation();

  useLayoutEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      setPrevScrollPos((prev) => {
        let barName2 = "bars small";
        if (prev >= currentScrollPos || currentScrollPos < 350) {
          barName2 = "bars";
        }
        setBarName(barName2);
        return currentScrollPos;
      });
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const drawerToggleClickHandler = () => {
    setSiteDrawerOpen(!sideDrawerOpen);
  };
  const removeBackdrop = () => {
    setSiteDrawerOpen(false);
  };

  return (
    <header className={`${barName} ${id2(pathPart)}`}>
      <div className="bars__links">
        <Backdrop clicked={removeBackdrop} show={sideDrawerOpen} />
        <Suspense fallback={<div></div>}>
          <FunctionProvider>
            <TopBar />
          </FunctionProvider>
          <NavBar admin={admin} />
          <ToggleButton clicked={drawerToggleClickHandler} />
          <SideDrawer show={sideDrawerOpen} clicked={drawerToggleClickHandler} />
        </Suspense>
      </div>
    </header>
  );
}
