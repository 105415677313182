import React, { Suspense, lazy } from "react";
import "./Agroweb.scss";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Bars from "./components/Bars/Bars.js";
import Home from "./containers/Home/Home.js";
import News from "./containers/News/News.js";
import SinglePost from "./containers/SinglePost/SinglePost.js";
import News2 from "./containers/News2/News2.js";
// import NoMatch from "./containers/NoMatch/NoMatch.js";
// import Admin from "./containers/Admin/Admin.js";
// import Admin_article from "./containers/Admin/Admin_article/Admin_article.js";
import HeadHelmet from "./components/HeadHelmet/HeadHelmet.js";
// import SimplePage from "./containers/SimplePage/SimplePage.js";
import Admin from "./containers/Admin/Admin.js";
import Admin_article from "./containers/Admin/Admin_article/Admin_article.js";
import { LocationProvider } from "./shared/LocationContext.js";
import withIdProvider from "./shared/HOC.js";
// import { useIdContext } from "./shared/IdProvider.js";

const SimplePage = lazy(() => import("./containers/SimplePage/SimplePage.js"));
const Footer = lazy(() => import("./components/Footer/Footer.js"));
const NoMatch = lazy(() => import("./containers/NoMatch/NoMatch.js"));

export default function Agroweb() {
  console.log("App rendering");
  // const { id } = useIdContext();
  // // const { id, slug } = useParams();
  // // const data = fetchData(id); // Pretpostavimo da dohvaćaš podatke

  // if (!post) {
  //   // return <Navigate to="/*" />; // Preusmeravanje na NoMatch stranicu
  // }
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <LocationProvider>
        <HeadHelmet />
        <div className="app">
          <Bars />
          <div className="app-wrapper">
            <div className="app-body">
              <Routes>
                <Route path="/" exact="true" element={React.createElement(withIdProvider(Home))} />
                <Route path="/tag" exact="true" element={React.createElement(withIdProvider(News2))} />
                <Route path="/tag/:category" exact="true" element={React.createElement(withIdProvider(News2))} />
                <Route path="/vesti/:category/:id" element={React.createElement(withIdProvider(SinglePost))} />
                <Route path="/vesti/:category" exact="true" element={React.createElement(withIdProvider(News2))} />
                <Route path="/vesti" exact="true" element={React.createElement(withIdProvider(News))} />
                <Route path="/najnovije-vesti" exact="true" element={React.createElement(withIdProvider(News2))} />
                {/* <Route path="/subvencije" exact="true" element={React.createElement(withIdProvider(News2))} /> */}
                <Route path="/zivot-na-selu/:category" exact="true" element={React.createElement(withIdProvider(News2))} />
                <Route path="/zivot-na-selu/:category/:id" element={React.createElement(withIdProvider(SinglePost))} />
                <Route path="/zivot-na-selu" exact="true" element={React.createElement(withIdProvider(News))} />
                {/* <Route path="/admin" exact="true" element={React.createElement(withIdProvider(Admin))} /> */}
                {/* <Route path="/admin/:id" exact="true" element={React.createElement(withIdProvider(Admin_article))} /> */}
                <Route path="/:slug" exact="true" element={<Suspense fallback={<p>Učitavanje...</p>}>{React.createElement(withIdProvider(SimplePage))}</Suspense>} />
                {/* <Route path="/no-match" element={React.createElement(withIdProvider(NoMatch))} /> */}
                <Route path="*" element={<Suspense fallback={<p>Učitavanje...</p>}>{React.createElement(withIdProvider(NoMatch))}</Suspense>} />
              </Routes>
            </div>
          </div>
          <Suspense fallback={<div></div>}>
            <Footer />
          </Suspense>
        </div>
      </LocationProvider>
    </Router>
  );
}
