import React, { lazy, Suspense } from "react";
import { Tweet } from "react-twitter-widgets";
import "./BodyText.scss";
import AdManagerSlot from "../AdvModule/AdManagerSlot.js";
import AsideBox from "../AsideBox/AsideBox.js";
import { asideBoxes2 } from "../../shared/shared.js";
import { useGlobalLocation } from "../../shared/LocationContext.js";
const PostImage = lazy(() => import("../../containers/SinglePost/img/PostImage.js"));

const setText = (item) => {
  if (Array.isArray(item.text)) {
    return item.text.map((it, index) => <p key={index} dangerouslySetInnerHTML={{ __html: it }}></p>);
  } else {
    return <p dangerouslySetInnerHTML={{ __html: item.text }}></p>;
  }
};

const setSubTitle = (item) => {
  const subtitle = item.subtitle || item.subtitle3;
  return subtitle ? subtitle === item.subtitle ? <h2 className="mainContent-subtitle">{subtitle}</h2> : <h3 className="mainContent-subtitle3">{subtitle}</h3> : null;
};

const setTweet = (item) => {
  if (item > 0) {
    return <Tweet tweetId={item} />;
  }
};
const setImg = (item) => {
  if (item.imgSrc) {
    return (
      <div>
        <Suspense fallback={<div></div>}>
          <PostImage src={item.imgSrc[0]} alt={item.imgSrc[1]} />
        </Suspense>
      </div>
    );
  }
};
const setBlockQuote = (item) => {
  if (item.boxInText) {
    return (
      <div>
        <blockquote>
          <h3>{item["boxInText"][0]}</h3>
          <p dangerouslySetInnerHTML={{ __html: item["boxInText"][1] }}></p>
        </blockquote>
      </div>
    );
  }
};
export default function BodyText(props) {
  const { location } = useGlobalLocation();
  //add more ads after this pasus
  let adsArray = [0, 3, 6, 9, 12];
  let slotNumberArray = ["div-gpt-ad-1710883713463-0", "div-gpt-ad-1710972143815-0", "div-gpt-ad-1714254283470-0", "div-gpt-ad-1726259996994-0", "div-gpt-ad-1726260272990-0"];
  return (
    <div className="bodyText">
      {props.bodyText.map((item, index) => {
        if (adsArray.includes(index)) {
          return (
            <React.Fragment key={index}>
              {setSubTitle(item)}
              {setText(item)}
              {setBlockQuote(item)}
              {setTweet(item.tweetId)}
              {setImg(item)}
              <div className="banner-wrapper">
                <AdManagerSlot adUnitPath={props.adUnitPath} slotNumber={slotNumberArray[adsArray.indexOf(index)]} />
              </div>
            </React.Fragment>
          );
        } else if (index === 1) {
          return (
            <React.Fragment key={index}>
              {setSubTitle(item)}
              {setText(item)}
              {setBlockQuote(item)}
              {setTweet(item.tweetId)}
              {setImg(item)}
              {/* <aside className="newsAside inText"> */}
              <aside className="newsAside inText">
                <AsideBox items={[...asideBoxes2[0]]} classes="newsAside" dispAds={false} adUnitPath={location.pathname} />
              </aside>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={index}>
              {setSubTitle(item)}
              {setText(item)}
              {setBlockQuote(item)}
              {setTweet(item.tweetId)}
              {setImg(item)}
            </React.Fragment>
          );
        }
      })}
    </div>
  );
}
