import React from "react";

const formatTime = (postTime, showYear = true) => {
  const months = ["januar", "februar", "mart", "april", "maj", "jun", "jul", "avgust", "septembar", "oktobar", "novembar", "decembar"];
  
  const postDate = new Date(postTime);
  const date = postDate.getDate();
  const month = months[postDate.getMonth()];
  const year = postDate.getFullYear();

  return showYear ? `${date}. ${month} ${year}.` : `${date}. ${month}`;
};

export default function TimeFormat({ timePost, classes = "default" }) {
  const showYear = classes !== "noDay";
  return <>{formatTime(timePost, showYear)}</>;
}
